/**
 * @flow
 */

/**
 * This function always expects a 'user_profile' value to exist as a global variable on window. Our `pre_base.html`
 * template file sets this variable, so there should always be one, although we may need to watch out for this not
 * being set in JS-only tests. The string interpolation should still work, but the URL might be wrong.
 *
 * @param url
 * @returns {string}
 */
export function urlWithWindowProfile(url: string): string {
  // eslint-disable-next-line i18next/no-literal-string
  return `/${window.user_profile}/${url}`;
}
