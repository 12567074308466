import moment from "moment-timezone";

import { urlWithWindowProfile } from "../../shared/utils/urlUtils";

// This sets our locale for moment globally. We do it in main.coffee because it's imported before all our code that
// relies on moment-timezone.
moment.locale(window.getLanguageCode());

// Used by Bulma's navigation bar / hamburger menu.
// Bulma's navbar needs some javascript for the hamburger menu.
document.addEventListener("DOMContentLoaded", () => {
  let navBarMenu = null;
  const navBurger = document.querySelector(".navbar-burger");
  if (navBurger) {
    navBurger.addEventListener("click", (event) => {
      event.target.classList.toggle("is-active");
      if (navBarMenu == null) {
        navBarMenu = document.querySelector(".navbar-menu");
      }
      navBarMenu?.classList.toggle("is-active");
    });
  }
});

// FIXME: We shouldn't need to define this globally anymore.
// FIXME: However, this is currently used by backbone templates and needs work to be removed.
// FIXME: Axosoft 10017
window.global = {
  util: {
    // FIXME: If you're using this in webpack, use `urlWithWindowProfile` directly, not window.global...
    URL: urlWithWindowProfile,
  },
};
